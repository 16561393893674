.my-work {
  background-color: var(--clr-dark);
  color: var(--clr-light);
  text-align: center;
}

.portfolio {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
