.my-services {
  background-color: var(--clr-dark);
  background-image: url(../../assets/pictures/stars.jpg);
  background-size: cover;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: var(--clr-light);
  text-align: center;
}

.section__title--services {
  color: var(--clr-accent);
  position: relative;
}

.section__title--services::after {
  content: '';
  display: block;
  width: 2em;
  height: 1px;
  margin: 0.5em auto 1em;
  background: var(--clr-light);
  opacity: 0.25;
}

.services {
  margin-bottom: 4em;
}

.service {
  max-width: 300px;
  margin: 0 auto;
}

@media (min-width: 800px) {
  .services {
    display: flex;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  .service + .service {
    margin-left: 2em;

  }
}