.nav {
  position: fixed;
  background: var(--clr-dark);
  color: var(--clr-light);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  transform: translateX(100%);
  transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
}

.nav__list {
  list-style: none;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav__item--btn {
  text-decoration: none;
  padding: 0;
  border: none;
  background: none;
}

.nav__link {
  color: inherit;
  font-weight: var(--fw-bold);
  font-size: var(--fs-h2);
  text-decoration: none;
}

.nav__link:hover {
  color: var(--clr-accent);
}

.nav-toggle {
  padding: 0.5em;
  background: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1000;
}

.nav-open .nav {
  transform: translateX(0);
}

.nav-open .nav-toggle {
  position: fixed;
}

.nav-open .hamburger {
  transform: rotate(0.625turn);
}

.nav-open .hamburger::before {
  transform: rotate(90deg) translateX(-6px);
}

.nav-open .hamburger::after {
  opacity: 0;
}
