header {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.logo {
  font-size: var(--fs-h3);
  font-family: var(--ff-secondary);
  top: 2px;;
}

.hamburger {
  display: block;
  position: relative;
}
.hamburger,
.hamburger::before,
.hamburger::after {
  background: var(--clr-accent);
  width: 2em;
  height: 3px;
  border-radius: 1em;
  transition: transform 250ms ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
}

.hamburger::before {
  top: 6px;
}

.hamburger::after {
  bottom: 6px;
}