.footer {
  background: #111;
  color: var(--clr-accent);
  text-align: center;
  padding: 2.5em 0;
}

.footer a { 
  color: inherit; 
  text-decoration: none;
}

.social-list__link:hover {
  opacity: .7;
}

.social-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 2em 0 0;
  padding: 0;
}

.social-list__item {
  margin: 0 .5em;
}

.social-list__link {
  padding: 0.5em;
}

