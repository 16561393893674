.portfolio__item {
  background: var(--clr-accent);
  overflow: hidden;
  cursor: pointer;
  border: none;
  padding: 0!important;
}

.portfolio__img {
  transition: transform 750ms cubic-bezier(0.5, 0, 0.5, 1), opacity 250ms linear;
}

.portfolio__item:focus {
  position: relative;
  z-index: 2;
}

.portfolio__img:hover,
.porfolio__item:focus .portfolio__img {
  transform: scale(1.2);
  opacity: 0.5;
}

.project-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: .8em;
  padding-bottom: .2em;

}

